// https://vuex.vuejs.org/en/state.html
export default {
  modoFuncionamiento: 'tpv', //tpv( por defecto), repartidor, comandero
  restauranteObj: null,
  almacenes: [],
  logo: null, //para descargar el logo, no hecho
  online: false,
  negocioObj: null,
  tabNavegacionModos: 0,
  configuracionTpv: {},
  forzarMuestraMensaje: false,
  datosTpv: {
    modos: [],
    mesas: [],
    dispositivosTpv: [],
    tickets: [],
    cuentas: [],
  }, //datos de plano tpv, y configuración tpv
  // datosTpvUsuarios: [],
  loaded: false,
  tiempoDomicilio: 10,
  tiempoRecogida: 10,
  recogidaActivada: false,
  domicilioActivado: false,
  horaInicio: 6,
  impuestos: [],
  mostrarFabAdd: true,
  modoDesarrollo: 'desarrollo', // desarrollo o produccion
  cargandoLazySnack: false,
  cargaLazy: [
    {
      accion: 'clientes/getClientes',
      loading: true,
      nombre: 'Clientes',
    },
    {
      accion: 'productos/getCategorias',
      loading: true,
      nombre: 'Categorias',
    },
    /*{
			accion: 'productos/getTodosCatalogos',
			loading: true,
			nombre: 'Productos'
		},
		{
			accion: 'productos/getProductos',
			loading: true,
			nombre: 'Productos Base'
		},*/
    {
      accion: 'productos/generarProductosCatalogos',
      loading: true,
      nombre: 'Productos Catálogos',
    },
  ],
  horaActual: null,
  uniqueId: null,
  abrirCalendario: false,
  abrirInfoCliente: false,
  // mostrarTeclado: false,
  necesarioTeclado: true,
  bajaCalidad: false,
  sizeBoton: false,
  //PAGINACIONES
  modoPaginacion: true,
  paginacionManejando: null, //pedidos, categorias, productos, internos, internosProductos, general
  pedidosPaginacionPedidos: 11,
  paginaPaginacionPedidos: 0,
  maximoPaginacionPedidos: 0,
  mostrandoPaginacionPedidos: 0,
  //categorias
  categoriasPaginacionCategorias: 15,
  paginaPaginacionCategorias: 0,
  maximoPaginacionCategorias: 0,
  mostrandoPaginacionCategorias: 0,
  //productos
  productosPaginacionProductos: 15,
  paginaPaginacionProductos: 0,
  maximoPaginacionProductos: 0,
  mostrandoPaginacionProductos: 0,
  //productos listado
  productosPaginacionProductosListado: 5,
  paginaPaginacionProductosListado: 0,
  maximoPaginacionProductosListado: 0,
  mostrandoPaginacionProductosListado: 0,
  //productos internos listado (uso con general)
  numItemsPaginacionGeneral: 5,
  paginaPaginacionGeneral: 0,
  maximoPaginacionGeneral: 0,
  // mostrandoPaginacionGeneral: 0,
  //pistola
  pistolaLeyendo: false,
  pistolaTexto: '',
  pistolaTextoDefinitivo: '',
  //producto info pistola
  infoProductoDialogo: false,
  infoProductoObj: {},
  //VERSIONES

  version: '3.13.13',

  //ZCIERRE
  zcierreDialog: false,
  zcierreFinDialog: false,
  zcierre: null,
  xcierre: 1,
  xcierreInicio: null,
  zcierreDatos: {},
  zcierreResumen: [],
  salidaDineroDialog: false,
  //ACTUALIZACIONES:
  actualizacionDialog: true,
  configuracionTpvGeneral: null, //{v2_version: ...}
  cashguardPosible: false,
  //NAVEGACIÓN
  tab: 0, //tab navegacion
};
