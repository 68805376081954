import moment from 'moment-timezone';
import { mostrarMoneda, redondeo } from '@/utils/helper.utils.js';
import { quitarAcentos, idToTexto, lineaQz } from './utils';
import { comandosF, valoresTicketDinamico } from './comandos';

export default async function (obj) {
  const { pedido, ticket, filtrarImpresos, impresionCocina } = obj;

  const ticketDinamico = valoresTicketDinamico(ticket);
  const comandos = comandosF(ticket);
  const categoriasExcluir = ticket.categoriasSelects
    ? ticket.categoriasSelects
    : [];
  const productosSi = ticket.productosSelectsSi
    ? ticket.productosSelectsSi
    : [];
  const productosNo = ticket.productosSelectsNo
    ? ticket.productosSelectsNo
    : [];
  const reglasEscritura = ticket.reglasEscritura;
  console.log(categoriasExcluir);

  const sizeCocina =
    ticket.size == 58 ? 'small' : ticket.sizeCocina ? ticket.sizeCocina : 'big';

  var devolverQz = []; // datos
  var productosImprime = [];
  const negocio = pedido.copyNegocio;
  const restaurante = pedido.copyRest;
  const timezone = restaurante.confGeneral.zonaHoraria
    ? restaurante.confGeneral.zonaHoraria
    : 'Europe/Madrid';
  var fechaMoment = pedido.fechaServidor.toDate()
    ? moment(pedido.fechaServidor.toDate()).tz(timezone)
    : moment(pedido.fechaServidorFinalEjecucion.toDate()).tz(timezone);
  let cabeceraMostrar = ''; // Mueve esta línea antes de su uso

  if (pedido.copyMesa && pedido.modoCopy) {
    cabeceraMostrar =
      'MESA ' + pedido.copyMesa.nombre + ' ' + pedido.modoCopy.nombre;
  } else if (pedido.modoCopy) {
    let num = pedido.numPedidoRestDiaModoMostrar
      ? ' ' + pedido.numPedidoRestDiaModoMostrar
      : pedido.numPedidoRestDia
      ? ' ' + pedido.numPedidoRestDia
      : '';
    cabeceraMostrar = pedido.modoCopy.nombre + num;
  }

  devolverQz.push(...[comandos.QZ_INIT]);

  devolverQz.push(
    ...[
      comandos.QZ_INIT, // init
      comandos.QZ_ALIGN_CENTER,
      lineaQz(
        fechaMoment.format('DD/MM/YYYY') +
          '-' +
          fechaMoment.format('HH:mm') +
          '-' +
          (pedido.modoCopy
            ? pedido.modoCopy.nombre
            : pedido.modo
          ).toUpperCase() +
          '-COCINA'
      ),
      comandos.QZ_FUENTE_NORMAL,
      comandos.QZ_ALIGN_CENTER,
      lineaQz(
        'TICKET: 00' +
          pedido.numPedidoRest +
          '  ' +
          'DIA: 00' +
          pedido.numPedidoRestDia
      ),
      comandos.QZ_BOLD,
      comandos.QZ_FUENTE_GRANDE,
      comandos.QZ_LINEA,
      comandos.QZ_INVERTIR,
      lineaQz(' ' + cabeceraMostrar + ' '),
      comandos.QZ_FUENTE_NORMAL,
      comandos.QZ_INVERTIR,
    ]
  );

  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_STOP_INVERTIR);

  if (!!pedido.comentarioPedidoTpv && !!pedido.comentarioPedidoTpv.length > 0) {
    devolverQz.push(
      ...[
        comandos.QZ_FUENTE_NORMAL,
        comandos.QZ_ALIGN_LEFT,
        comandos.QZ_LINEA_HORIZONTAL,
        comandos.QZ_FUENTE_MEDIANA,
        lineaQz(pedido.comentarioPedidoTpv),
        comandos.QZ_FUENTE_NORMAL,
        comandos.QZ_LINEA_HORIZONTAL,
      ]
    );
  }
  if (!!pedido.notaRestaurante) {
    devolverQz.push(
      ...[
        comandos.QZ_FUENTE_NORMAL,
        comandos.QZ_LINEA_HORIZONTAL,
        comandos.QZ_ALIGN_LEFT,
        comandos.QZ_FUENTE_MEDIANA,
        lineaQz('COMENTARIO'),
        lineaQz(pedido.notaRestaurante),
        comandos.QZ_FUENTE_NORMAL,
        comandos.QZ_LINEA_HORIZONTAL,
      ]
    );
  }
  devolverQz.push(comandos.QZ_BOLD);
  devolverQz.push(comandos.QZ_ALIGN_LEFT);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(
    sizeCocina === 'big'
      ? comandos.QZ_FUENTE_GRANDE
      : comandos.QZ_FUENTE_MEDIANA
  );

  var impuestos = 0;
  var productos = pedido.productos;
  var imprime = false;

  productos
    .sort((a, b) =>
      a.ordenCat > b.ordenCat
        ? 1
        : b.ordenCat > a.ordenCat
        ? -1
        : a.orden > b.orden
        ? 1
        : -1
    )
    .forEach((prd, linea) => {
      var cantidadImprimir = prd.cantidad;
      var cantidadCarrito = !!pedido.eliminado ? 0 : prd.cantidad;
      let impresionCocina = !!pedido.impresionCocina
        ? pedido.impresionCocina
        : [];

      console.log(prd.categoria);
      console.log('productosNo ' + !productosNo.includes(prd.id));
      console.log('cat ' + categoriasExcluir.includes(prd.categoria));
      if (
        (!categoriasExcluir.includes(prd.categoria) &&
          !productosNo.includes(prd.id)) ||
        productosSi.includes(prd.id)
      ) {
        let imprimeConcreto = false;
        let idAux = [
          prd.id,
          ...(!!Array.isArray(prd.opciones) ? prd.opciones : []),
        ].join('_');

        let foundImpresion = impresionCocina.find(
          (imP) => imP.id == idAux && imP.menuIdConcreto == prd.menuIdConcreto
        );
        let anular = false;
        if (filtrarImpresos) {
          if (!!foundImpresion) {
            if (foundImpresion.cantidad < prd.cantidad && prd.cantidad != 0) {
              imprime = true;
              imprimeConcreto = true;
              cantidadImprimir = cantidadCarrito - foundImpresion.cantidad;
            } else if (foundImpresion.cantidad > cantidadCarrito) {
              imprime = true;
              imprimeConcreto = true;
              cantidadImprimir = foundImpresion.cantidad - cantidadCarrito;
              anular = true;
            }
          } else if (cantidadCarrito != 0) {
            imprime = true;
            imprimeConcreto = true;
          }
          if (imprimeConcreto) {
            productosImprime.push({
              id: idAux,
              cantidad: anular ? -cantidadImprimir : cantidadImprimir,
              menuIdConcreto: prd.menuIdConcreto ? prd.menuIdConcreto : null,
            });
          }
        } else if (cantidadCarrito != 0) {
          productosImprime.push({
            id: idAux,
            cantidad: prd.cantidad,
          });
          imprime = true;
          imprimeConcreto = true;
        }
        if (imprimeConcreto) {
          let nombreImprimir = prd.nombreTpv ? prd.nombreTpv : prd.nombre;
          if (anular) {
            nombreImprimir = '[ANUL] ' + nombreImprimir;
          }
          nombreImprimir = nombreImprimir.substring(
            0,
            ticketDinamico.substringCocina + 1
          ); // Para 80mm - 20  y para 58mm - 13

          let s = '';
          if (prd.porPeso) {
            s = s + prd.cantidad + 'kg ' + quitarAcentos(nombreImprimir);
          } else {
            s = s + cantidadImprimir + 'x ' + quitarAcentos(nombreImprimir);
          }

          devolverQz.push(lineaQz(s));
          if (prd.opciones) {
            prd.opciones.forEach((e, i) => {
              var nombreOp = prd.opcionesName[i];
              nombreOp = nombreOp.substring(0, ticketDinamico.substringCocina); // Para 80mm - 20  y para 58mm - 11

              let s = '';
              s = '   -' + quitarAcentos(nombreOp);

              devolverQz.push(lineaQz(s));
            });
          }
          if (prd.comentarioCocina && prd.comentarioCocina.length > 0) {
            s = 'COMENTARIO: ' + quitarAcentos(prd.comentarioCocina);
            devolverQz.push(lineaQz(s));
          }
        }
      }
    });

  if (!imprime) return false;
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);

  devolverQz.push(comandos.QZ_CORTE);

  return { productos: productosImprime, datos: devolverQz };
}
